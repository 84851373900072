/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const DancingScript_400Regular = require('./DancingScript_400Regular.ttf');
export const DancingScript_500Medium = require('./DancingScript_500Medium.ttf');
export const DancingScript_600SemiBold = require('./DancingScript_600SemiBold.ttf');
export const DancingScript_700Bold = require('./DancingScript_700Bold.ttf');
